<template>
    <div v-if="done">
        <div class="container-fluid"  style="height: 100%; width: 100%; position: relative;">
            <div class="home py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-xl-6" style="position:relative;">

                            <div class="title" style="z-index: 4;position: relative;">
                                {{ data.title_1 }}
                                <span>{{ data.title_bold_1 }}</span>
                            </div>

                            <div class="text" style="z-index: 4;position: relative;">
                                {{ data.text_1 }}
                            </div>
                            
                            <div class="buttons mt-4" style="z-index: 4;position: relative;">
                                <RouterLink class="button" :to="b.link" v-for="b in data.buttons">
                                    {{ b.text }}
                                </RouterLink>
                            </div>
                            <img class="img-banner-home-mobile d-block d-xl-none" :src="data.image_1" alt="home-banner">
                        </div>
                        <div class="col-xl-6 d-none d-xl-block">
                            <img loading="lazy" class="img-banner-home" :src="data.image_1" alt="home-banner">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid area_activity py-2">
            <div class="row">
                <div class="col-12">
                    <div class="container">
                        <div class="row help home">
                            <div class="col-12">
                                <div class="title mb-5 mt-4">
                                    {{title_2}}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <!-- <div class="area_activity"> -->
                    <div class="container mb-5">
                        <div class="row">
                            <div class="col-12">
                                <center>
                                    <n-carousel :slides-per-view="tab_width_2" autoplay draggable :show-dots="false"> 
                                        <img loading="lazy" v-for="c of categories" class="area-pic" :src="c.image_url" :alt="c.name">
                                    </n-carousel>
                                </center>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>

        <div class="container mt-4">
            <n-carousel
                style="height: 240px"
                :show-dots="true"
                draggable
                class="homepage-projects"
                :slides-per-view="1"
            >
                <n-carousel-item class="home-projects" v-for="p in projects">
                    <div class="project-home" style="position: relative;" :style="{background: p.data_home[0].gradient_css}">
                        <div class="project-info">
                            
                            <div class="info-head">
                                <div class="home-button">
                                    <RouterLink to="/portfolio" class="d-inline-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                            </svg>
                                        <span>{{button}}</span>
                                    </RouterLink>
                                </div>
                                <h2>{{p.data_home[0].title_home}}</h2>
                            </div>
                            <div class="info-content">
                                <p>{{p.data_home[0].content_home}}</p>
                            </div>
                            <div class="image-front">
                                <img loading="lazy" :src="p.data_home[0].imagem_do_produto" :alt="p.data_home[0].title_home">
                            </div>
                        </div>
                        <div class="image-back" :style="{ 'background-image': 'url(' + p.data_home[0].background_image_home + ')' }"></div>
                        
                    </div> 
                </n-carousel-item>
            </n-carousel>
        </div>   
 
        <div class="container-fluid mt-4 p-0">
            <div class="row">
                <div class="area_integrations" style="background-image: url('https://cms.vivify.pt/wp-content/uploads/2024/12/background-integration.webp');">
                    <div class="container">
                        <div class="row integration_title">
                            <div class="col-12">
                                <div class="title">
                                    {{integrators.title}}
                                </div>                               
                            </div>
                        </div>
                        <div class="row mt-5 mb-5">
                            <div class="col-12 col-xl-6">
                                <!-- DESKTOP -->
                                <img loading="lazy" class="w-100 d-none d-xl-block" :src="integrators.image_left_side" :alt="integrators.title">
                                <!-- TABLE/MOBILE -->
                                <div class="d-block d-xl-none w-100 question-mark" style="position: relative;height: 4em;">
                                    <transition name="fade">
                                        <svg v-if="!integrators_flag" @click="integrators_flag=true;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-question-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                                        </svg>
                                        <svg v-else @click="integrators_flag=false;" style="position:absolute;color:white;top:0;right: 0;" xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" fill="currentColor" class="d-block d-xl-none bi bi-x-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                        </svg>
                                    </transition>
                                </div>
                                <div class="d-block d-xl-none w-100 home-container-integrator">
                                    <transition name="fade">
                                        <img loading="lazy" :alt="integrators.title" style="padding-left: 10%;padding-right: 10%;position: absolute;top:0;left:0" key="first" v-if="!integrators_flag" class="home-image-integrators w-100" :src="integrators.image_left_side">
                                        <n-carousel style="color:white;position: absolute;top:0;left:0" key="second" v-else :slides-per-view="1"  draggable :show-dots="false" show-arrow >  
                                            <n-carousel-item class="py-5"  v-for="i in integrators['slider']"> 
                                                <span class="title">{{ i.title }}</span>
                                                <div v-html="i.content"></div>
                                            </n-carousel-item>
                                        </n-carousel>
                                    </transition>
                                </div>
                                
                               
                            </div>
                            <div class="d-none d-xl-block col-6">
                                <n-carousel :slides-per-view="1" draggable :show-dots="false" show-arrow style="color:white">  
                                    <n-carousel-item class="py-5"  v-for="i in integrators['slider']"> 
                                        <span class="title">{{ i.title }}</span>
                                        <div v-html="i.content"></div>
                                    </n-carousel-item>
                                </n-carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="container my-5">
            <div class="testimonials-title" v-html="title"></div>
            <n-carousel  :slides-per-view="tab_width_3" draggable :show-dots="true"> 
                <n-carousel-item class="testimonials py-4 py-sm-5" v-for="(t,count) in testimonials" style="position: relative;" :style="'border-radius: 20px;'"> 
                    <div class="testimonials"> 
                        <div class="row">
                            <div class="col-12">
                                <div class="image">
                                    <div class="opacity"></div>
                                    <img loading="lazy" :alt="t.title" :src="t.image">
                                </div>
                            </div> 
                            <div class="col-12">
                                <div :class="'pt-4 py-2 px-4 title-'+(count%2==1?'2':'1')">
                                    {{ t.title }}
                                </div>
                            </div> 
                            <div class="col-12 content-col">
                                <div style="height: 100%;" :class="'py-2 px-4 testimonials-text content-'+(count%2==1?'2':'1')"> 
                                    {{ t.content }} 
                                </div>
                            </div>
                            <div class="col-12">
                                <div :class="'pb-4 py-2 px-4 footer-'+(count%2==1?'2':'1')">
                                    {{ t.name }}
                                    <br>
                                    <div class="positon-text">
                                        {{ t.position }}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </n-carousel-item> 
  
                <template #dots="{ total, currentIndex, to }">
                    <ul class="custom-dots" style="position: relative; top: -5px;">
                        <li
                            v-for="index of total"
                            :key="index"
                            :class="{ ['is-active']: currentIndex === index - 1 }"
                            @click="to(index - 1)"
                        ></li>
                    </ul>
                </template>
            </n-carousel>
        </div>
    </div>
</template>

<script>
import testimonials from '../components/testimonials/testimonials';
import { useLanguageStore } from './stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);
import { useHead } from 'unhead'



export default {
    components:{testimonials},
    data(){
        return{
            data:[],
            testimonials:[],
            title:'',
            title_2:'',
            button:'',
            tab_width_2: window.innerWidth <= 425 ? 2 : window.innerWidth <= 720 ? 3 : window.innerWidth <= 1024 ? 5 : 6,
            tab_width_3:window.innerWidth <= 768 ? 1 : window.innerWidth <= 1199 ? 2 : 3,
            categories:[],
            projects:[],
            integrators:[],
            integrators_flag:false,
            done: false,
            width:window.innerWidth
        }
    },
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        useHead({
            title: 'VIVIFY - Home',
            meta: [
                { name: 'description', content: 'Home Content' },
            ],
        })
        return { selectedLanguage, changeLanguage };
	},
    async created(){

        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/home'+this.selectedLanguage.lang_url ,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                this.data=res.home[0];
                this.data.image_1 = this.data.image_1.url;
                this.testimonials= this.data.testimonials[0].testimony ;
                this.categories=res.categories;
                res.projects.forEach(p =>{
                    if(p.data_home==false){
                        p.data_home = [];
                        p.data_home[0] = {
                            gradient_css: '',
                            background_image_home: '',
                        }
                    }
                })
                this.projects=res.projects;
                setTimeout(() =>this.refresh=true , 250)
                this.$parent.$parent.$parent.addtoPercentage(33);

                this.title = this.data.testimonials[0].title;
                this.title_2 = this.data.area_of_activity[0].title;
                this.button = this.data.area_of_activity[0].button;
                this.integrators = this.data.integrators[0];

                this.done = true;

                
 
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }

  
            })

    },

    mounted() {
        window.addEventListener("resize", this.updateTabWidth);
    },
    unmounted() {
        window.removeEventListener("resize", this.updateTabWidth);
    },

    methods:{
        updateTabWidth() {
            // console.log('service')
            this.width = window.innerWidth;
            this.tab_width_2 = window.innerWidth <= 425 ? 2 : window.innerWidth <= 720 ? 3 : window.innerWidth <= 1024 ? 5 : 6;
            this.tab_width_3 = window.innerWidth <= 768 ? 1 : window.innerWidth <= 1199 ? 2 : 3;
        },
        changeIntegratorsContent(event,field){
            if(window.innerWidth<992){
                this.classFlag = true;
            }
            if(event.target.children.length == 0){
                event.target.classList = ['inner-dot'];
            }else{
                event.target.children[0].classList = ['inner-dot'];
            }
            this.integrators_content = this.integrators[field];
        },
        close(){
            this.classFlag = false;
        }

    }
}
</script>

<style>

</style>