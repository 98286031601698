<template>
    <div>
        <div class="services-banner" :style="{ 'background-image': 'url(' + data.image_1 + ')' }">  

            <div class="blue-filter"></div>
            <div class="container">
                <div class="row services py-5">
                    <div class="col-12 col-sm-8 title">
                        {{ data.title_1 }}
                    </div>
                    <div class="col-8 d-none d-sm-block">
                    </div>
                    <div class="col-12 col-md-6 text" v-html="formatText(data.text_1)">
                    </div>
                    <div class="col-6 d-none d-sm-block">
                    </div>
                </div>
            </div>
            <!-- v-if="bk_v"  -->
            <img loading="lazy"  class="d-none d-sm-block bk_v_2" src="../../assets/green_logo.png" alt="">
        </div>
    </div>

    <div class="light-gray-background">
        <div class="container">
            <div class="row help py-5">
                <div class="col-12 title bring-front">
                    {{ data.title_2 }}
                </div>
            </div>
        </div>
        <div class="ideia">
            <div class="container">
                <div class="row "> 
                    <div class="col-12">
                        <center>
                            <n-carousel :slides-per-view="tab_width" autoplay draggable :show-dots="false"> 
                                <img loading="lazy" v-for="c of categories" class="area-pic" style="height: 150px;" :src="c.image_url" :alt="c.name">
                            </n-carousel>
                        </center>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-4 mb-4 p-0 timeline">
            <div class="row">
                <div class="col-12">
                    <div class="container">
                        <div class="row my-4 my-md-5">
                            <div class="col-12 col-md-6 bring-front">
                                <div class="services-processes-col">
                                    <div class="services-processes">
                                        <div class="title">
                                            {{processes.title}}
                                        </div>
                                        <n-timeline :horizontal="width<=767">
                                            <n-timeline-item v-for="(p,index) of processes.process" style="cursor: pointer;" :ref="'timeline-'+index"  @click="changeP('timeline-'+index,p.content,p.title,p.sub_title)"
                                                type="info"
                                                :title="p.title"
                                            >
                                            <!-- :content="width<=767 ? '' : p.sub_title" -->

                                            </n-timeline-item>
                                        </n-timeline>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 content bring-front services-conception">
                                <div class="ps-md-4"> 
                                    <div class="title">{{ process_title }}</div>
                                    <div class="desc">{{ process_desc }}</div><!-- v-if="width<=767"-->
                                    <span class="p_content" v-html="process_content"></span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="topics">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-md-center text-start title bring-front ">{{ data.title_3 }}</div>
                    <div class="col-12 text-md-center text-start subtitle bring-front">{{ data.text_3 }}</div>
                    <div class="col-12">
                        <n-collapse  arrow-placement="right" :default-expanded-names="collapse" v-if="refresh">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-4 topic" v-for="(t,count) of data.topics">
                                    <n-collapse-item class="topic-title" :title="t.name" :name="''+count+++''" >
                                        <div class="content" v-for="p of t.points">{{ p.text }}</div>
                                    </n-collapse-item>
                                </div>
                            </div>
                        </n-collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tools">
        <div class="container">
            <div class="content">
                <div class="title">
                    {{ data.title_4 }}
                </div>
                <div class="subtitle">
                    {{ data.text_4 }}
                </div>
                <div class="row tool">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 containeer" v-for="t of data.tools">
                        <span>{{ t.name }}</span>
                        <div class="group">
                            <img loading="lazy" :src="to.logo.url" style="" v-for="to of t.tool" :title="to.alt">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>
  
<script>
import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    data(){
        return{
            data:[],
            collapse:[],
            refresh:true,
            categories:[],
            // tab_width: window.innerWidth <= 425 ? 1 : window.innerWidth <= 720 ? 2 : window.innerWidth <= 1024 ? 4 : 5,
            tab_width: window.innerWidth <= 425 ? 2 : window.innerWidth <= 767 ? 3 : window.innerWidth <= 1024 ? 4 : 5,
            bk_v:window.innerWidth <= 550 ? false : true,
            processes:[],
            process_content:'',
            process_title:'',
            sub_title:'',
            width:window.innerWidth,
        }
    },

    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},


    mounted() {
        window.addEventListener("resize", this.updateTabWidth);
    },
    unmounted() {
        window.removeEventListener("resize", this.updateTabWidth);
    },

    async created(){
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/services'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                this.categories=res.categories;
                this.data=res.services[0];
                this.collapse=Array(this.data.topics.length).fill().map((_, i) => ''+i+'')
                this.refresh=false;
                this.processes=res.services[0].processes[0];

                
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }
            })
            setTimeout(() =>this.refresh=true , 250)
            this.$parent.$parent.$parent.addtoPercentage(33);
            console.log();
            this.changeP('timeline-'+0,this.processes.process[0].content,this.processes.process[0].title,this.processes.process[0].sub_title)

    },
    methods:{
        changeP(ref,txt,title,desc){
            if(!this.$refs[ref][0].$el.classList.value.split(' ').includes('active')){
                if(document.getElementsByClassName('n-timeline-item n-timeline-item--info-type n-timeline-item--default-line-type active').length>0){
                    document.getElementsByClassName('n-timeline-item n-timeline-item--info-type n-timeline-item--default-line-type active')[0].classList.value = 'n-timeline-item n-timeline-item--info-type n-timeline-item--default-line-type';
                }
                this.$refs[ref][0].$el.classList = this.$refs[ref][0].$el.classList.value + ' active'
            }else{
                this.$refs[ref][0].$el.classList = 'n-timeline-item n-timeline-item--info-type n-timeline-item--default-line-type'
            }
            this.process_content=txt;
            this.process_title=title;
            // this.sub_title=stitle;
            this.process_desc=desc;
        },
        formatText(txt){
            if(txt==undefined || txt=='')return '';
            return txt.replace(/\. /g, '.<br>');
        },

        updateTabWidth() {
            // console.log('service')
            // this.tab_width = window.innerWidth <= 425 ? 1 : window.innerWidth <= 720 ? 2 : window.innerWidth <= 1024 ? 4 : 5;
            this.tab_width = window.innerWidth <= 425 ? 2 : window.innerWidth <= 767 ? 3 : window.innerWidth <= 1024 ? 4 : 5;
            this.width = window.innerWidth;
            this.bk_v = window.innerWidth <= 550 ? false : window.innerWidth <= 1024 ? true : true;
        }, 
    }
}
</script>

<style>

</style>