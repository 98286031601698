<template>
    <div style="position: relative;">
        <!-- v-if="bk_v" -->
        <img loading="lazy"  class="d-none d-sm-block bk_v_1 no-select" src="../../assets/green_logo.png" alt=""> 
        <!-- <div class="container-fluid"> -->
            <div class="row portfolio">
                <div class="col-12">
                    <div class="container py-5">
                        <div class="row">
                            <!-- <div class="col-12 col-sm-9 small-title  bring-front">
                                <h2 class="gray-text">{{data_blogs_2.blogs_title_1}}</h2>
                            </div>
                            <div class="col-8 d-none d-sm-block">
                            </div>
                            <div class="col-12 col-md-8 col-lg-5 small-title bring-front" >
                                <span class="gray-text">{{data_blogs_2.blogs_title_2}}</span>
                            </div>
                            <div class="col-7 d-none d-lg-block">
                            </div> -->

                            
                            <div class="col-12 col-sm-8 small-title bring-front">
                                {{data_blogs_2.blogs_title_2}}
                            </div>
                            <div class="col-8 d-none d-sm-block">
                            </div>
                            <div class="col-12 col-sm-9 title bring-front" >
                                {{data_blogs_2.blogs_title_1}}
                            </div>
                            <div class="col-7 d-none d-sm-block">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
        <div class="row projects">
            <div class="col-12">
                <div class="container my-5">
                    <div class="row">
                        <!-- .slice(0, 8) -->
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" style="justify-items: center;z-index: 0; padding-bottom: calc(var(--bs-gutter-x)* .5);" v-for="p in data_blogs" >
                            <div class="project">
                                <RouterLink :to="{ name: 'Blog', params: { id: p.id } }">
                                    <div class="image">
                                        <img loading="lazy" :src="p.img" alt="">
                                        <div class="content d-flex">
                                            <span class="title my-auto mx-auto">{{p.title}}</span> 
                                        </div>
                                    </div>
                                </RouterLink>
                                <div class="content" style="min-height: 0;"> 
                                    <div class="col-12">
                                        <span class="mt-2" v-for="(c, index) in p.categories">
                                            <span v-if="c.id!=9">
                                                {{ c.name }}<span v-if="index < p.categories.length - 1"><b> · </b></span>
                                            </span>
                                        </span>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    </div>

</template>
  
<script>

import { useLanguageStore } from '../stores/language';
import { computed } from "vue";
  
export default {
    data(){
        return{
            data:[],
            show_menu:false,
            projects:[],  
            data:[],
            data_blogs:[],
            data_blogs_2:[],

            bk_v:window.innerWidth <= 550 ? false : true,
        }
    },
    setup(){
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };
        return { selectedLanguage, changeLanguage };
	},

    async created(){
        this.$parent.$parent.$parent.resetFlag();        

        await fetch('https://cms.vivify.pt/wp-json/options/culture'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{ 
                this.data_blogs=res['blogs'];

                this.data=res.culture[0]; 
            }) 

        await fetch('https://cms.vivify.pt/wp-json/options/blog'+this.selectedLanguage.lang_url)
            .then(res => res.json()) 
            .then(res =>{  
                this.data_blogs_2=res['blog'][0];

                
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');

                if (description) {
                    description.setAttribute('content', this.data_blogs_2.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data_blogs_2.metakeywords || ''); 
                }
 

            })
        this.$parent.$parent.$parent.addtoPercentage(33);
         
        
    },
    methods:{ 

    },
    mounted() { 
    }
}
</script>

<style>

</style>