<template>
    <div class="cmms" v-if="done">

        <div class="container py-5">
            <div class="row">
                <div class="col-12 text-center">
                    <img loading="lazy" :src="cmms.image" class="w-100 cmms-main-image" alt="Demo">
                </div>
            </div>


            <div class="row mt-5">
                <div class="col-12">
                    <center>
                        <n-carousel :slides-per-view="tab_width_1" autoplay draggable :show-dots="false"> 
                            <h4 v-for="k in cmms.keys">{{ k.key }}</h4>
                        </n-carousel>
                    </center>
                </div>
            </div>

            <div class="row  mt-4">
                <div class="col-12 col-lg-6 align-content-center concept" >
                    <img loading="lazy" class="p-3 d-lg-none d-sm-block d-none background-image" :src="concept.image" alt="background">
                    <div class="title mb-3">
                        {{ concept.title }}
                    </div>
                    <div class="content">
                        {{ concept.content }}
                    </div>
                </div>
                <div class="d-none d-lg-block col-lg-6 align-content-center">
                    <img loading="lazy" class="w-100 p-3" :src="concept.image" alt="background">
                </div>
            </div>
 
        </div>

        <!-- <div> 

            <div v-for="(feature, index) in cmms.features" :key="index" class="row">
                <div
                    :class="{'order-last': index % 2 === 1, 'order-first': index % 2 === 0}"
                    class="col-6 p-3"
                    style="background-color: #9b59b6"
                > 
                    <img loading="lazy" :src="feature.image" />
                </div>
                <div
                    :class="{'order-first': index % 2 === 1, 'order-last': index % 2 === 0}"
                    class="col-6 p-3"
                    style="background-color: #3498db" 
                > 
                    <div v-html="feature.content"></div>
                </div>
            </div>


        </div> -->

<!-- 
        <div>
            <div v-for="(feature, index) in cmms.features" :key="index" class="row"> 
                <div
                    :style="{
                        background: getGradientColor(index, feature)
                    }"
                    class="w-100"
                > 
                    <div class="container">
                        <div class="row"> 
                            <div
                                :class="{'order-last': index % 2 === 1, 'order-first': index % 2 === 0} "
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                            >
                                <img loading="lazy" :src="feature.image" />
                            </div>
                            <div
                                :class="{'order-first': index % 2 === 1, 'order-last': index % 2 === 0}"
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                                style="min-height:400px;"
                            >
                                <div v-html="feature.content"></div>
                            </div>
                        </div>
                    </div>  

                </div>
            </div>
        </div> -->
        <div class="row banner">
            <div class="col-12 align-content-center" style="height: 250px" :style="{'background-color': banner.color}">
                <div class="title text-center">{{ banner.title }}</div>
            </div>
        </div>


        <div class="side-by-side-div">
            <div v-for="(feature, index) in cmms.features" :key="index" class="row"> 
                <div
                    :style="{ background: getGradientColor(index, feature) }"
                    class="w-100"
                >
                    <!-- Conteúdo dentro da container -->
                    <div class="container">
                        <div class="row"> 
                            <div
                                :class="{
                                    'order-last': index % 2 === 1 && isLgScreen,
                                    'order-first': index % 2 === 0 && isLgScreen
                                }"
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                                :style="{ color: feature.text_color }"
                            >
                                <img loading="lazy" :src="feature.image" :alt="banner.title" />
                            </div>
                            <div
                                :class="{
                                    'order-first': index % 2 === 1 && isLgScreen,
                                    'order-last': index % 2 === 0 && isLgScreen
                                }"
                                class="col-12 col-lg-6 p-5 side-by-side-color-col"
                                style="min-height: 400px;"
                                :style="{ color: feature.text_color }"
                            >
                                <div v-html="feature.content"></div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <div class="preditive">
            <div class="container">
                <div class="row ">
                    <div class="col-12 mb-3 mt-2">
                        <div class="title text-center">{{preditive.title}}</div>
                    </div>
                    <div class="col-12 mb-4 slider-col">
                        <center>
                            <n-carousel :slides-per-view="tab_width_2" draggable >
                                <div v-for="s in solutions" class="carousel-border mx-1 my-3" style="position: relative;"> 
                                    <img loading="lazy" class="carousel-img" :src="s.image" :alt="s.title" style="    cursor: auto;">
                                    <div class="carousel-image-overlay d-flex">
                                        <span class=" my-auto mx-auto text-center">{{ s.title }}</span> 
                                    </div>
                                </div>

                                <template #dots="{ total, currentIndex, to }">
                                    <ul class="custom-dots" style="bottom:0;">
                                        <li
                                        v-for="index of total"
                                        :key="index"
                                        :class="{ ['is-active']: currentIndex === index - 1 }"
                                        @click="to(index - 1)"
                                        ></li>
                                    </ul>
                                </template>

                                </n-carousel> 
                        </center>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="done" class="container-fluid light-gray-background py-5" style="padding-left: 0; padding-right: 0;">
            <div class="row my-3"> 
                <div class="d-none d-lg-block col-lg-1 col-xl-3"></div>
                <div class="col-12 col-lg-10 col-xl-6 form-challenge ps-lg-5"  > 
                    <div class="container form-cmms">
                        <div class="title d-sm-block" >{{demo_form.form_title}}<span>.</span></div>
                            <div class="row mt-3">
                                <div class="col-12 py-1">
                                    <input v-model="nome" type="text" :placeholder="demo_form.name" :class="{error:errors.includes('nome')}" :disabled="loading">
                                </div>
                                <div class="col-12 py-1">
                                    <input v-model="email" type="email" :placeholder="demo_form.email" :class="{error:errors.includes('email')}" :disabled="loading">
                                </div>
                                <div class="col-12 py-1">
                                    <input v-model="empresa" type="text" :placeholder="demo_form.company" :class="{error:errors.includes('empresa')}" :disabled="loading">
                                </div>
                                <div class="col-12 py-1">
                                    <textarea v-model="mensagem" :placeholder="demo_form.message" :class="{error:errors.includes('mensagem')}" :disabled="loading"></textarea>
                                </div> 
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <div class="d-none d-sm-block col-sm-7 col-xl-8"></div>
                                        <div class="col-12 col-sm-5 col-xl-4">
                                            <button @click="recaptcha" class="submit" :disabled="loading">
                                                <span v-if="!loading">{{demo_form.submit}}</span>
                                                <span v-else><n-spin size="small"/></span>
                                            </button>
                                        </div>  
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="d-none d-lg-block col-lg-1 col-xl-3"></div>
            </div> 
        </div>
</template>
  
<script>
import { useNotification } from 'naive-ui' 
import { useReCaptcha } from "vue-recaptcha-v3";
import { useLanguageStore } from '../stores/language';
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import fetchRetry from 'fetch-retry';
const fetch = fetchRetry(global.fetch);

export default {
    components:{},
    data(){
        return{ 
            concept:[],
            cmms: null,
            done: false, 
            banner:null,
            preditive:null,
            solutions:null,
            tab_width_1: window.innerWidth <= 425 ? 2 : 3,
            isLgScreen: window.innerWidth >= 992,
            tab_width_2: window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3,
            demo_form:null,
            nome: '',
            email: '',
            empresa: '',
            mensagem: '',
            errors: [],
            loading:false
        }
    },

    async created(){
 
        this.$parent.$parent.$parent.resetFlag();
        await fetch('https://cms.vivify.pt/wp-json/options/cmms'+this.selectedLanguage.lang_url,{retries: 5,retryDelay: 800})
            .then(res => res.json()) 
            .then(res =>{
                this.concept = res['cmms'][0].concept[0];
                this.cmms=res['cmms'][0];
                this.banner = res['cmms'][0].banner[0];
                this.preditive = res['cmms'][0].preditive[0];
                this.solutions = res['cmms'][0].preditive[0].solutions;
                this.demo_form = res['cmms'][0].demo_form;
                this.done = true;

                
                const description = document.getElementById('meta-description');
                const keywords = document.getElementById('meta-keywords');
                // todo não é data
                if (description) {
                    description.setAttribute('content', this.data.metadescription || '');  
                }
                if (keywords) {
                    keywords.setAttribute('content', this.data.metakeywords || ''); 
                }
        })

        this.$parent.$parent.$parent.addtoPercentage(33);
        
    },

    setup(){
        
		const languageStore = useLanguageStore();
		const selectedLanguage = computed(() => languageStore.globalLang);
        const changeLanguage = (lang) => {
            languageStore.setLanguage(lang);
        };

        const notification = useNotification()
        return {
            error(content,title) {
                notification.error({
                    title:title,
                    content: content
                })
            },
            success(content,title) {
                notification.success({
                    title:title,
                    content: content
                })
            },
            selectedLanguage, changeLanguage
        }
    },


    mounted() {
        window.addEventListener('resize', this.updateScreenSize); 
        window.addEventListener("resize", this.updateTabWidth);
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            this.recaptcha = async () => {
                await recaptchaLoaded()
                this.token = await executeRecaptcha('login')
                this.submitForm();
            }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);  
        window.removeEventListener("resize", this.updateTabWidth);
    },

    methods: { 
        async submitForm() {
            if(this.checkForm()){
                this.loading=true;
                $('#error_submit').hide();
 
                await fetch('https://cms.vivify.pt/wp-json/options/send-email-cmms',{
                    method:'POST',
                    headers: {
                        'Content-Type': 'application/json',  // Ensure the content type is JSON
                    },
                    body:JSON.stringify(
                        {
                            token:this.token,
                            subject:'PEDIDO DE DEMO - MANUTENÇÃO (CMMS)',
                            message:'EMPRESA :'+this.empresa+"\nNOME: "+this.nome+"\nEMAIL: "+this.email+"\nMENSAGEM: "+this.mensagem
                        }
                    ) 
                })
                .then(res =>{
                    if(res.status==200){
                        this.success('Proposta enviada com sucesso!','ENVIADO COM SUCESSO'); 

                        this.resetForm();
                        this.date = null;
                    }else{
                        this.error('Erro ao enviar Proposta','ENVIADO SEM SUCESSO') 

                    }
                    this.loading=false; 
                })
            } 
        },
        resetForm(){
            this.empresa=null;
            this.nome=null;
            this.email=null;
            this.mensagem=null;
        },
        checkForm(){
            this.errors=[];
            if(!this.validEmail(this.email))this.errors.push('email'); 
            if(this.empresa=='' || this.empresa==null)this.errors.push('empresa');
            if(this.nome=='' || this.nome==null)this.errors.push('nome'); 
            if(this.mensagem=='' || this.mensagem==null)this.errors.push('mensagem'); 

            if(this.errors.length>=1)return false;
            return true;
        },
        validEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        getGradientColor(index, feature) {
            const isLgScreen = window.innerWidth >= 992;
            const direction = isLgScreen ? 'to right' : 'to bottom';

            let background_color;
            let background_color_2;

            if (isLgScreen) {
                background_color = feature.background_color;
                background_color_2 = feature.background_color_2;
            } else {
                background_color = feature.background_color_2;
                background_color_2 = feature.background_color;
            }
             
            const isEvenIndex = index % 2 === 0;
            const color1 = (isEvenIndex && !isLgScreen) ? background_color : background_color_2;
            const color2 = (isEvenIndex && !isLgScreen)  ? background_color_2 : background_color;

            return `linear-gradient(${direction}, ${color1} 50%, ${color2} 50%)`;
        },


        updateTabWidth() {
            this.tab_width_1 = window.innerWidth <= 425 ? 2 : 3;
            this.tab_width_2 = window.innerWidth <= 768 ? 1 : window.innerWidth <= 992 ? 2 : 3;
        },

        updateScreenSize() {
            this.isLgScreen = window.innerWidth >= 992; // Atualiza o estado
        },
    
    }
}
</script>

<style>

</style>